import React, { useEffect, useState } from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { Link } from "gatsby";
import useWindowDimensions from "../utils/windowSize";
import DropdownMenu from "../components/DropdownMenu";
import { ACTIVE_PAGE } from "../components/nav/utils";
import { isMp4 } from "../utils/functions";
import {
  BlogHeaderImage,
  BlogHeaderVideo,
  CardTagButton,
} from "../page-components/blog/styles";
import ShareButtons from "../components/sharebuttons";
import ContactRequestContainer from "../page-components/blog/ContactRequestContainer/ContactRequestContainer";
import { vrLinks } from "./vr";
import { kebabCase } from "lodash";
import {
  Content,
  ContentWrapper,
  Divider,
  HeaderContainer,
  PostHeader,
} from "../page-components/blog/BlogPost.styles";

interface MediaComponentProps {
  mediaSrc: string;
  imageFocus?: string;
}

const MediaComponent: React.FC<MediaComponentProps> = ({
  mediaSrc,
  imageFocus = "",
}) => {
  if (!mediaSrc) return null;

  return isMp4(mediaSrc) ? (
    <BlogHeaderVideo loop autoPlay muted style={{ objectPosition: imageFocus }}>
      <source src={mediaSrc} type="video/mp4" />
    </BlogHeaderVideo>
  ) : (
    <BlogHeaderImage
      src={mediaSrc}
      alt=""
      style={{ objectPosition: imageFocus }}
    />
  );
};

const getIndex = (searchQuery: string) => {
  const urlQuery = /^(?:\?view=)(.*)/.exec(searchQuery);
  const query = urlQuery ? urlQuery[1].replaceAll("+", " ") : null;
  if (!query) return 0;

  const titles = vrLinks.map((link) => link.title);
  if (titles.includes(query))
    return titles.findIndex((title) => title === query);
  else return 0;
};

export default function VrPage() {
  const [linkIndex, setLinkIndex] = useState<number | null>(null);
  const [url, setUrl] = useState("");
  const tags = ["VR", "As-Built Documentation", "Point Cloud", "LiDAR"];

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLinkIndex(getIndex(window.location.search));
    }
  }, []);

  const handleClick = (title: string, index: number) => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location.href);
      url.searchParams.set("view", title);
      window.history.pushState({}, "", url.toString());
      setLinkIndex(index);
    }
  };

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.VRBLOG}>
      <Seo
        title="Visual Capture | Robotic Imaging"
        description="Virtual reality capture services using Matterport tools. Come see the kind of virtual reality products delivered by Robotic Imaging"
      />
      <div style={{ padding: "60px 0 0 0" }}></div>
      <Divider />
      <HeaderContainer>
        <PostHeader>Practical VR for Adaptive Re Use</PostHeader>
      </HeaderContainer>
      <ContentWrapper>
        <Content>
          <MediaComponent mediaSrc="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/blog-images%2FVR-Page-Header.jpeg?alt=media&token=bb2dd482-ffd3-42bb-9f63-3bf50af69b43" />
          <br />
          <small>
            {tags.map((tag, i) => (
              <CardTagButton key={tag}>
                <small>
                  <Link
                    className="blog-post__tag-link"
                    to={`/tags/${kebabCase(tag)}/`}
                  >
                    {tag}
                  </Link>
                  {i < tags.length - 1 ? "" : ""}
                </small>
              </CardTagButton>
            ))}
          </small>
          <small className="small_text">Robotic Imaging</small>
          <small className="small_text">April 2, 2024</small>

          <small style={{ paddingTop: "10px" }}>
            <ShareButtons
              title={"Practical VR for Adaptive Re Use"}
              url={url}
              tags={tags}
            />
          </small>
          <br />

          <div className="site__header__button-container-tags">
            {vrLinks.map((link, index) => (
              <button
                key={link.title}
                onClick={() => handleClick(link.title, index)}
                disabled={linkIndex === index}
                className={`site__header__button${
                  linkIndex === index ? "-disabled" : ""
                }`}
              >
                {link.title}
              </button>
            ))}
          </div>

          <DropdownMenu
            index={linkIndex}
            setIndex={setLinkIndex}
            options={vrLinks}
          />
          <iframe
            className="deliverable__iframe"
            title="virtual-walkthrough"
            src={linkIndex !== null ? vrLinks[linkIndex].url : ""}
            frameBorder="0"
            allowFullScreen
            allow="xr-spatial-tracking"
          />
          <div className={"blog-post__html"}>
            <h2>Practical VR</h2>
            <p>
              Robotic Imaging harnesses the power of Virtual Reality (VR) to
              create, store, and share visually-rich 3D models of physical
              spaces. We help planning, design, construction and facilities
              teams adopt VR fast and without a headset. Here are some of the
              ways our clients are benefiting:
            </p>
            <ol>
              <li>
                <strong>Improved Design & Visualization</strong>
              </li>
              <p>
                3D/VR allows architects and designers to create 3D models and
                immersive environments, helping stakeholders better understand
                the design concept and make more informed decisions. Project
                stakeholders can explore the virtual project together, which
                facilitates communication, increases misunderstandings and
                decreases project scoping.
              </p>
              <li>
                <strong>Site Planning and Logistics</strong>
              </li>
              <p>
                VR can simulate site conditions, logistics, and the flow of
                materials and personnel. This aids in better planning and
                optimizing construction processes for maximum efficiency. By
                identifying issues early, improving project planning, and
                reducing rework, VR can lead to cost savings throughout the
                construction process.
              </p>
              <li>
                <strong>Remote Inspections</strong>
              </li>
              <p>
                VR can be used for remote inspections, reducing the need for
                on-site visits and improving the efficiency of the inspection
                process. Project managers can track construction progress using
                VR by overlaying virtual models with actual site conditions.
                This allows for accurate monitoring and comparison of planned
                vs. actual progress.
              </p>
              <li>
                <strong>Marketing & Sales</strong>
              </li>
              <p>
                Developers can use VR to showcase properties to potential buyers
                or tenants, providing immersive tours of projects that are still
                in the planning or construction phase.
              </p>
              <li>
                <strong>Data Integration</strong>
              </li>
              <p>
                VR can be integrated with Building Information Modeling (BIM)
                systems, allowing for seamless data exchange and a more
                comprehensive understanding of the project. Construction
                companies that adopt VR demonstrate their commitment to
                innovation, which can give them a competitive advantage in a
                rapidly evolving industry.
              </p>
              <li>
                <strong>Safety Training & Planning</strong>
              </li>
              <p>
                Construction workers can undergo safety training in a virtual
                environment, allowing them to practice and prepare for
                real-world scenarios without physical risk. VR provides a safe
                and controlled environment for training construction workers and
                equipment operators, helping them develop skills and gain
                experience.
              </p>
            </ol>
            <p>
              Incorporating VR in construction processes can lead to better
              decision-making, reduced errors, and increased efficiency. It
              empowers stakeholders with a deeper understanding of projects,
              ultimately resulting in improved project outcomes, from planning
              and design to construction and beyond.
            </p>
          </div>
        </Content>
      </ContentWrapper>
      <Divider />
      <Content>
        <div
          className="page-navigation"
          style={{ flexDirection: "column", borderBottom: "none" }}
        >
          <ContactRequestContainer />
        </div>
      </Content>{" "}
    </SiteWrapper>
  );
}
